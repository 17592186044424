import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playTrack, appendTrack } from '../../redux/musicPlayerSlice';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import { BELLINO_BASE_URL, DEFAULT_RELEASE_IMG, FetchPageData, getImageFullPath } from '../../Classes/Utilities';
import { TrackInfo } from '../../Types/Track';
import { VideoInfo } from '../../Types/VideoInfo';
import { MusicStatistics } from '../../Types/MusicStatistics';
import { CommentInfo } from '../../Types/CommentInfo';
import { UsersLikeInfo } from '../../Types/UsersLikeInfo';
import { Track } from '../../Classes/Track';
import { addTrackLyrics, addTrackVideo, deleteTrackVideo, updateTrackVideoDuration } from '../../Services/Tracks';
import { getYoutubeVideoData, searchTrackYoutubeVideo } from '../../Services/External/Google';

import NotFound from '../NotFound/NotFound';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import SliderList from '../../Containers/SliderList/SliderList';
import Title from '../../Components/Title/Title';
import Tag from '../../Components/Tag/Tag';
import Image from '../../Components/Image/Image';
import Link from '../../Components/Link/Link';
import Button from '../../Components/Button/Button';
import ArtistLink from '../../Components/ArtistLink/ArtistLink';
import MusicItemStatistics from '../../Components/MusicStatistics/MusicStatistics';
import ItemPlaybar from '../../Components/ItemPlaybar/ItemPlaybar';
import VideoCard from '../../Components/VideoCard/VideoCard';
import UsersLikes from '../../Components/UsersLikes/UsersLikes';
import Card from '../../Components/Card/Card';
import TrackMenuButton from '../../Components/MenuButton/TrackMenuButton';
import UsersComments from '../../Components/UsersComments/UsersComments';

import MoreReleasesDialog from './partials/MoreReleasesDialog';
import FriendsListenersDialog, { FriendsListener } from '../_partials/FriendsListenersDialog';
import AddVideoDialog from './partials/AddVideoDialog';
import AddLyricsDialog from './partials/AddLyricsDialog';
import AddSimilarTracksDialog from './partials/AddSimilarTracksDialog';

import classes from './Track.module.css';

type TrackLyricsInfo = {
    text?: string;
    usersLikeInfo?: UsersLikeInfo;
};

function TrackPage(props: any) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const trackID = searchParams.get('tid');

    const { userSessionData } = useUserSessionData();
    const { updatePageMetdata } = usePageMetdata();
    const dispatch = useDispatch<AppDispatch>();

    const isLoading = useRef<boolean>();
    const trackLyricsEditorElementRef = useRef(null);

    const [track, setTrack] = useState<Track>(new Track());
    const [trackStatistics, setTrackStatistics] = useState<MusicStatistics>();
    const [trackVideos, setTrackVideos] = useState<VideoInfo[]>([]);
    const [trackLyrics, setTrackLyrics] = useState<TrackLyricsInfo>();
    const [similarTracks, setSimilarTracks] = useState<TrackInfo[]>([]);
    const [comments, setComments] = useState<CommentInfo[]>([]);

    const [isMoreReleasesDialog, setIsMoreReleasesDialog] = useState(false);
    const [isFriendsListenersDialog, setIsFriendsListenersDialog] = useState(false);
    const [isAddVideoDialogVisible, setIsAddVideoDialogVisible] = useState(false);
    const [isAddLyricsDialogVisible, setIsAddLyricsDialogVisible] = useState(false);
    const [isAddSimilarTracksDialogVisible, setIsAddSimilarTracksDialogVisible] = useState(false);
    const [isInLyricsEditMode, setIsInLyricsEditMode] = useState(false);

    useEffect(() => {
        if (!isLoading.current && trackID) {
            isLoading.current = true;
            setTrack(new Track());
            setTrackVideos([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/track?id=${trackID}`).then((response: any) => {
                if (response.success) {
                    const trackObj = new Track({ ...(response.result?.trackInfo as TrackInfo), videos: response.result?.videos });

                    updatePageMetdata(response.result?.metadata);
                    setTrack(trackObj);
                    setTrackStatistics(response.result?.statistics);
                    setSimilarTracks(
                        response.result?.similarTracks?.map((st: any) => {
                            return { ...st, imgPath: getImageFullPath(st.imgPath, 'Track') };
                        })
                    );
                    setComments(response.result?.comments);
                    setTrackLyrics(response.result?.lyrics ?? undefined);

                    if (trackObj.videos.length > 0) {
                        const updatedVideoPromises = trackObj.videos.map(
                            video =>
                                new Promise<VideoInfo>(async resolve => {
                                    if (video.externalId && !video.duration) {
                                        const videoYoutubeData = await getYoutubeVideoData(video.externalId);
                                        updateTrackVideoDuration(video.externalId, videoYoutubeData?.duration?.total_seconds ?? 0);
                                        resolve({
                                            ...video,
                                            duration: videoYoutubeData?.duration?.total_seconds ?? 0,
                                            durationStr: videoYoutubeData?.duration?.string ?? '00:00'
                                        } as VideoInfo);
                                    } else {
                                        resolve(video);
                                    }
                                })
                        );

                        Promise.all(updatedVideoPromises).then(updatedVideos => {
                            setTrackVideos(updatedVideos);
                        });
                    } else {
                        searchTrackYoutubeVideo(trackObj).then(video => {
                            if (video?.videoID) {
                                addTrackVideo(trackID, video.videoID, video.videoType, video.videoDuration, '1').then(addedVideo => {
                                    if (addedVideo) {
                                        setTrackVideos(prev => [...prev, addedVideo]);
                                    }
                                });
                            }
                        });
                    }
                } else {
                    setTrack(new Track());
                }

                setTimeout(() => {
                    isLoading.current = false;
                }, 100);
            });
        }
    }, [trackID, updatePageMetdata]);

    const friendsListeners = useMemo(
        () =>
            (trackStatistics?.friendsPlays ?? [])
                .map(friendPlays => {
                    return {
                        userId: friendPlays.UserID,
                        itemType: 'Track',
                        itemID: trackID,
                        itemNumOfPlays: friendPlays.Plays
                    } as FriendsListener;
                })
                .sort((f1, f2) => {
                    return f2.itemNumOfPlays - f1.itemNumOfPlays;
                }),
        [trackID, trackStatistics?.friendsPlays]
    );

    const trackLyricsSpans = useMemo(
        () =>
            trackLyrics?.text
                ?.trim()
                ?.split('\n')
                ?.map((lyricsLine, index) => (
                    <span key={index}>
                        {lyricsLine}
                        <br />
                    </span>
                )),
        [trackLyrics?.text]
    );

    if (!trackID || (!track.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer showPreloader={!track.isInit || !userSessionData.isReady}>
            <Section backgroundColor="gradient-opacity-white">
                <div className={classes.trackInfoContainer}>
                    <div>
                        {track.appearsInAlbums?.[0]?.id ? (
                            <Link href={`/album?aid=${track.appearsInAlbums[0].id}`}>
                                <Image
                                    src={track.coverImgPath}
                                    srcFallback={DEFAULT_RELEASE_IMG}
                                    viewWidth={250}
                                    viewHeight={250}
                                    viewMode="background-image"
                                    imageClass={'l-shadow'}
                                ></Image>
                            </Link>
                        ) : (
                            <Image
                                src={track.coverImgPath}
                                srcFallback={DEFAULT_RELEASE_IMG}
                                viewWidth={250}
                                viewHeight={250}
                                viewMode="background-image"
                                imageClass={'l-shadow'}
                            ></Image>
                        )}
                    </div>
                    <div>
                        <div>
                            <div className={classes.albumTitle}>{track.name}</div>
                            <div className="fs-160">
                                <ArtistLink artists={track.artists} textColor={'dark-blue'}></ArtistLink>
                            </div>
                            <div className="text-gray mt-100">
                                {track.year}
                                {track.appearsInAlbums && track.appearsInAlbums.length > 0 && (
                                    <>
                                        <span> &nbsp;•&nbsp; From </span>
                                        <Link href={`/album?aid=${track.appearsInAlbums[0].id}`} textColor="dark-blue">
                                            {track.appearsInAlbums[0].name}
                                        </Link>
                                    </>
                                )}
                                {track.appearsInAlbums && track.appearsInAlbums.length > 1 && (
                                    <>
                                        <span> and </span>
                                        <Button
                                            viewMode="decorationLink"
                                            textColor="dark-blue"
                                            onClick={() => {
                                                setIsMoreReleasesDialog(true);
                                            }}
                                        >
                                            {track.appearsInAlbums.length - 1} other releases
                                        </Button>
                                    </>
                                )}
                            </div>
                            <div className="mt-100 mb-100">
                                {track.genres.map(trackGenre => (
                                    <Tag
                                        key={trackGenre}
                                        viewMode="default"
                                        text={trackGenre}
                                        href={`/genre?name=${encodeURIComponent(trackGenre)}`}
                                    ></Tag>
                                ))}
                            </div>
                        </div>
                        <div className={classes.musicStatistics}>
                            <MusicItemStatistics
                                numOfListeners={trackStatistics?.listeners ?? 0}
                                numOfPlays={trackStatistics?.plays ?? 0}
                                numOfMyPlays={trackStatistics?.myPlays ?? 0}
                                numOfMyFriendsPlays={trackStatistics?.friendsPlays ? Object.keys(trackStatistics.friendsPlays).length : 0}
                                onMyFriendsPlaysClick={() => {
                                    setIsFriendsListenersDialog(true);
                                }}
                                onMyPlaysClick={() => {
                                    navigate(`/listenings?type=track&itemID=${trackID}`);
                                }}
                            ></MusicItemStatistics>
                        </div>
                    </div>
                </div>
                <TrackMenuButton
                    buttonView="white-circle"
                    buttonSize="large"
                    containerCustomClass={classes.trackMenuBtn}
                    track={track}
                ></TrackMenuButton>
            </Section>

            <section className={classes.trackVideosContainer}>
                <Title centered={true}>Videos</Title>
                <div className={classes.videos}>
                    {trackVideos?.length > 0 ? (
                        <>
                            {trackVideos.map((trackVideo, index) => (
                                <VideoCard
                                    key={trackVideo.id}
                                    videoInfo={trackVideo}
                                    isReadOnly={userSessionData.isReady && !userSessionData.userInfo?.id}
                                    cardSize="large"
                                    onVideoPlay={() => {
                                        dispatch(
                                            playTrack({
                                                id: track.id,
                                                name: track.name,
                                                artists: track.artists,
                                                videos: [
                                                    {
                                                        id: trackVideo.id,
                                                        externalId: trackVideo.externalId,
                                                        duration: trackVideo.duration
                                                    }
                                                ]
                                            })
                                        );
                                    }}
                                    onVideoPlus={() => {
                                        dispatch(
                                            appendTrack({
                                                id: track.id,
                                                name: track.name,
                                                artists: track.artists,
                                                videos: [
                                                    {
                                                        id: trackVideo.id,
                                                        externalId: trackVideo.externalId,
                                                        duration: trackVideo.duration
                                                    }
                                                ]
                                            })
                                        );
                                    }}
                                    onVideoDelete={() => {
                                        track.DeleteVideoByIndex(index);
                                        setTrackVideos(trackVideos.filter(t => t.id !== trackVideo.id));
                                        deleteTrackVideo(trackVideo.id.toString());
                                    }}
                                ></VideoCard>
                            ))}
                        </>
                    ) : (
                        <button
                            className={classes.addBtn}
                            onClick={() => {
                                setIsAddVideoDialogVisible(true);
                            }}
                        >
                            +
                        </button>
                    )}
                </div>
                {trackVideos?.length > 0 && userSessionData.userInfo && (
                    <div className={classes.toolbar}>
                        <Button
                            viewMode="decorationLink"
                            textColor="dark-blue"
                            onClick={() => {
                                setIsAddVideoDialogVisible(true);
                            }}
                        >
                            + Add Video
                        </Button>
                    </div>
                )}
            </section>

            <section className={classes.trackLyricsContainer}>
                <Title centered={true}>Lyrics</Title>
                <div>
                    {trackLyrics?.text?.trim()?.length ? (
                        isInLyricsEditMode ? (
                            <textarea ref={trackLyricsEditorElementRef} className={classes.lyricsEditor}>
                                {trackLyrics?.text?.trim()}
                            </textarea>
                        ) : trackLyrics.text === '#!-NoLyrics-!#' ? (
                            <div className={classes.noLyrics} title="This track have no lyrics">
                                ♫
                            </div>
                        ) : (
                            <div className={classes.lyrics}>{trackLyricsSpans}</div>
                        )
                    ) : (
                        <button className={classes.addBtn} onClick={() => setIsAddLyricsDialogVisible(true)}>
                            +
                        </button>
                    )}
                </div>
                {Boolean(trackLyrics?.text?.trim()?.length && userSessionData.userInfo) && (
                    <div className={classes.toolbar}>
                        {isInLyricsEditMode ? (
                            <>
                                <Button
                                    viewMode="fancy"
                                    onClick={() => {
                                        setIsInLyricsEditMode(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    viewMode="fancy"
                                    onClick={() => {
                                        const trackLyricsEditorElement = trackLyricsEditorElementRef?.current
                                            ? (trackLyricsEditorElementRef.current as HTMLTextAreaElement)
                                            : null;

                                        setTrackLyrics(prevState => ({ ...prevState, text: trackLyricsEditorElement?.value ?? '' }));
                                        setIsInLyricsEditMode(false);
                                        addTrackLyrics(trackID, trackLyricsEditorElement?.value ?? '');
                                    }}
                                >
                                    Save
                                </Button>
                            </>
                        ) : (
                            <>
                                <UsersLikes itemType="lyrics" itemId={trackID} usersLikeInfo={trackLyrics?.usersLikeInfo}></UsersLikes>
                                <Button
                                    viewMode="decorationLink"
                                    textColor="dark-blue"
                                    onClick={() => {
                                        setIsInLyricsEditMode(true);
                                        setTimeout(() => {
                                            const trackLyricsEditorElement = trackLyricsEditorElementRef?.current
                                                ? (trackLyricsEditorElementRef.current as HTMLTextAreaElement)
                                                : null;

                                            if (trackLyricsEditorElement) {
                                                trackLyricsEditorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
                                                trackLyricsEditorElement.focus();
                                            }
                                        }, 200);
                                    }}
                                >
                                    Edit lyrics
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </section>

            <Section backgroundColor="white">
                <Title>Similar Tracks</Title>
                <div>
                    {similarTracks?.length > 0 ? (
                        <SliderList>
                            {similarTracks.map(similarTrack => (
                                <Card
                                    key={similarTrack.id}
                                    imgOverlays={[
                                        {
                                            showMode: 'nonHover',
                                            className: classes.similarTrackOverlay,
                                            overlayChildren: (
                                                <>
                                                    <b>{similarTrack.name}</b>
                                                    <br />
                                                    {similarTrack.artists[0].name}
                                                </>
                                            )
                                        },
                                        {
                                            showMode: 'onHover',
                                            overlayChildren: (
                                                <ItemPlaybar viewMode="overlay" itemType="Track" itemId={similarTrack.id}>
                                                    <div className={classes.similarTrackLink}>
                                                        <Link href={`/track?tid=${similarTrack.id}`}>
                                                            <b>{similarTrack.name}</b>
                                                        </Link>
                                                        <br />
                                                        <Link href={`/artist?aid=${similarTrack.artists[0].id}`}>
                                                            {similarTrack.artists[0].name}
                                                        </Link>
                                                    </div>
                                                </ItemPlaybar>
                                            )
                                        }
                                    ]}
                                    imgPath={similarTrack.imgPath ?? `${BELLINO_BASE_URL}/Images/default-release.png`}
                                ></Card>
                            ))}
                        </SliderList>
                    ) : (
                        <p>There are no similar tracks yet.</p>
                    )}
                    {userSessionData.isReady && userSessionData.userInfo && (
                        <>
                            <br />
                            <div className="text-dark-blue">
                                <Button
                                    viewMode="decorationLink"
                                    textColor="dark-blue"
                                    onClick={() => {
                                        setIsAddSimilarTracksDialogVisible(true);
                                    }}
                                >
                                    + Add Similar Tracks
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Section>

            <Section backgroundColor="white">
                <Title>Comments</Title>
                <div>
                    <UsersComments
                        itemType="Track"
                        itemId={trackID}
                        comments={comments}
                        activeUserInputComment={
                            userSessionData.userInfo
                                ? {
                                      userId: userSessionData.userInfo.id,
                                      userFirstName: userSessionData.userInfo.firstName,
                                      userLastName: userSessionData.userInfo.lastName,
                                      userImgPath: userSessionData.userInfo.imgPath ?? ''
                                  }
                                : undefined
                        }
                        onAddComment={commentInfo => {
                            setComments(prevComments => [...prevComments, commentInfo]);
                        }}
                        onDeleteComment={commentId => {
                            setComments(prevComments => {
                                return prevComments.filter(c => c.id.toString() !== commentId.toString());
                            });
                        }}
                    ></UsersComments>
                </div>
            </Section>

            <MoreReleasesDialog
                isVisible={isMoreReleasesDialog}
                albums={track.appearsInAlbums && track.appearsInAlbums.length > 1 ? track.appearsInAlbums.slice(1) : []}
                onCloseDialog={() => setIsMoreReleasesDialog(false)}
            ></MoreReleasesDialog>

            <FriendsListenersDialog
                friendsListeners={friendsListeners}
                isVisible={isFriendsListenersDialog}
                onCloseDialog={() => {
                    setIsFriendsListenersDialog(false);
                }}
            ></FriendsListenersDialog>

            <AddVideoDialog
                trackFullName={track.fullName}
                trackVideos={trackVideos}
                isVisible={isAddVideoDialogVisible}
                isAdmin={userSessionData?.userInfo?.id === '1'}
                onCloseDialog={() => setIsAddVideoDialogVisible(false)}
                onAdd={async (videoExternalId, videoType, videoDuration) => {
                    if (userSessionData.userInfo?.id) {
                        const videoInfo = await addTrackVideo(trackID, videoExternalId, videoType, videoDuration, userSessionData.userInfo.id);

                        if (videoInfo) {
                            setTrackVideos(prev => [...prev, videoInfo]);
                        }
                    }
                }}
            ></AddVideoDialog>

            <AddLyricsDialog
                trackFullName={track.fullName}
                isVisible={isAddLyricsDialogVisible}
                onAdd={lyrics => {
                    addTrackLyrics(trackID, lyrics);
                    setTrackLyrics(prevState => ({ ...prevState, text: lyrics }));
                }}
                onCloseDialog={() => setIsAddLyricsDialogVisible(false)}
            ></AddLyricsDialog>

            <AddSimilarTracksDialog
                trackId={trackID}
                isVisible={isAddSimilarTracksDialogVisible}
                onCloseDialog={() => setIsAddSimilarTracksDialogVisible(false)}
            ></AddSimilarTracksDialog>
        </AppMainContainer>
    );
}

export default TrackPage;
