import { BELLINO_BASE_URL, FetchData } from '../Classes/Utilities';

export const getUserItemRating = async (itemType: string, itemID: string) => {
    let rating: number | undefined;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0) {
        const responseData = await FetchData(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetItemRating&type=${itemType}&itemID=${itemID}`);

        if (responseData) {
            rating = responseData;
        }
    }

    return rating;
};

export const rateItem = async (itemType: string, itemID: string, rating: number, isFirstUserRating: boolean) => {
    let isSuccess: boolean = false;

    if (itemType.trim().length > 0 && itemID.trim().length > 0 && rating >= 0) {
        const responseData = await FetchData(
            `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=${isFirstUserRating ? 'RateItem' : 'UpdateRateItem'}&type=${itemType}&itemID=${itemID}&rating=${rating}`
        );

        if (responseData) {
            isSuccess = true;
        }
    }

    return isSuccess;
};

export const unrateItem = async (itemType: string, itemID: string) => {
    let isSuccess: boolean = false;

    if (itemType.trim().length > 0 && itemID.trim().length > 0) {
        const responseData = await FetchData(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=UnrateItem&type=${itemType}&itemID=${itemID}`);

        if (responseData) {
            isSuccess = true;
        }
    }

    return isSuccess;
};
