import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, PostData, FetchPageData } from '../../Classes/Utilities';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Link from '../../Components/Link/Link';
import ChartList, { ChartItem } from '../../Components/Charts/ChartList/ChartList';

import classes from './Genre.module.css';
import { getWikiPage } from '../../Services/External/Wikipedia';

function GenrePage(props: any) {
    const [searchParams] = useSearchParams();
    const genreName = searchParams.get('name') ?? '';

    usePageMetdata({ title: genreName });

    const isLoading = useRef<boolean>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [wikiText, setWikiText] = useState<string>();
    const [wikiURL, setWikiURL] = useState('');
    const [chartArtists, setChartArtists] = useState<ChartItem[]>([]);

    useEffect(() => {
        if (genreName && !isLoading.current) {
            isLoading.current = true;
            setIsLoaded(false);
            setChartArtists([]);
            setWikiText(undefined);
            setWikiURL('');

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/genre?name=${encodeURIComponent(genreName)}`).then((response: any) => {
                if (response.success && response.result) {
                    const fetchedChartItems = response.result.topArtists?.map((chartArtist: any, cIndex: number) => {
                        const itemId = chartArtist.id;
                        const mainTitle = chartArtist.name;
                        const mainTitleHref = `/artist?aid=${chartArtist.id}`;
                        let subTitle = <span className="fs-105 text-gray">{chartArtist.origin}</span>;

                        return {
                            id: itemId,
                            mainTitleHref,
                            mainTitle,
                            subTitle,
                            date: '',
                            position: cIndex + 1,
                            image: `${BELLINO_BASE_URL}/${chartArtist.image}`,
                            score: chartArtist.score,
                            tags: chartArtist.genre?.split(';')?.map((genre: any, gIndex: number) => {
                                return {
                                    id: `${genre}_${gIndex}`,
                                    name: genre,
                                    href: `/genre?name=${encodeURIComponent(genre)}`
                                };
                            })
                        } as ChartItem;
                    });

                    if (response.result.wiki && response.result.wikiURL) {
                        setWikiText(response.result.wiki);
                        setWikiURL(response.result.wikiURL);
                    } else {
                        getWikiPage(`${genreName.replace('-', ' ')} music`, ['genre']).then(wikiRes => {
                            if (wikiRes?.pageSummary && wikiRes?.pageURL) {
                                setWikiText(wikiRes.pageSummary);
                                setWikiURL(wikiRes.pageURL);

                                PostData(`${BELLINO_BASE_URL}/api/genres`, {
                                    action: 'UpdateGenreInfo',
                                    genreName: genreName,
                                    genreWiki: wikiRes.pageSummary,
                                    genreWikiURL: wikiRes.pageURL
                                });
                            } else {
                                setWikiText("Can't Load Wiki ... ");
                            }
                        });
                    }

                    setChartArtists(fetchedChartItems);
                } else {
                    setChartArtists([]);
                }

                isLoading.current = false;
                setIsLoaded(true);
            });
        }
    }, [genreName]);

    if (genreName.trim().length <= 0) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer showPreloader={!isLoaded}>
            <Section backgroundColor="off-white">
                <Title>{genreName}</Title>
                <div className={classes.genreWiki}>
                    <div>
                        {(wikiText ?? 'Loading...')
                            .split('\n')
                            .filter(t => t.trim().length > 0)
                            .map((wikiParagraph, index) => (
                                <p key={index}>
                                    {wikiParagraph}
                                    <br />
                                </p>
                            ))}
                    </div>
                </div>
                {wikiURL && (
                    <div className="mt-100">
                        <Link href={wikiURL} target="_blank" textColor="dark-blue">
                            » Taken from Wikipedia
                        </Link>
                    </div>
                )}
            </Section>
            <Section backgroundColor="white">
                <Title>
                    <Link href={`/charts?time=alltime&type=top_artists&genre=${genreName}`} viewMode="See-More-Hover">
                        Top Artists
                    </Link>
                </Title>
                <ChartList chartItems={chartArtists}></ChartList>
            </Section>
        </AppMainContainer>
    );
}

export default GenrePage;
