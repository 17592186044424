import { BELLINO_BASE_URL, FetchData } from '../Classes/Utilities';
import { SearchItemInfo } from '../Components/MultiSearchSelection/MultiSearchSelection';

export const searchArtists = async (serachText: string, exludedArtistId?: string) => {
    const searchResults: SearchItemInfo[] = [];

    if (serachText.trim().length > 0) {
        const responseData = await FetchData(
            `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchArtists&search=${serachText}&data=${exludedArtistId ?? ''}`
        );

        if (responseData) {
            responseData.forEach((artistResult: any) => {
                const artistId = artistResult.ID;
                const artistName = artistResult.Name;

                searchResults.push({
                    id: artistId,
                    name: artistName
                });
            });
        }
    }

    return searchResults;
};
