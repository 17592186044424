import { BELLINO_BASE_URL, FetchData, PostData } from '../Classes/Utilities';
import { ChatInfo, ChatData, MessageStatus } from '../Types/Chat';
import { MusicItemType } from '../Types/MusicItemType';

export const getUserChats = async () => {
    const conversions: ChatInfo[] = [];

    const responseData = await FetchData(`${BELLINO_BASE_URL}/api/messages?action=GetUserConversions`);

    if (responseData?.success && typeof responseData?.result === 'object') {
        conversions.push(...responseData.result);
    }

    return conversions;
};

export const getChatData = async (friendID: string) => {
    let chatData: ChatData = { participants: [], messages: [] };

    if (friendID.toString().trim()?.length > 0) {
        const responseData = await FetchData(`${BELLINO_BASE_URL}/api/messages?action=GetConversion&friendID=${friendID}`);

        if (responseData?.success && typeof responseData?.result === 'object') {
            chatData = responseData.result;
        }
    }

    return chatData;
};

export const sendMessage = async (friendID: string, message: string) => {
    let messageId: string | undefined;

    if (friendID.toString().trim()?.length > 0 && message.toString().trim()?.length > 0) {
        const formData = new FormData();
        formData.append('action', 'SendPM');
        formData.append('uid', friendID);
        formData.append('msg', message);

        const responseData = await PostData(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, formData, 'text');

        if (responseData) {
            messageId = responseData;
        }
    }

    return messageId;
};

export const deleteMessage = async (messageID: string) => {
    let isSuccess: boolean = false;

    if (messageID.toString().trim()?.length > 0) {
        const formData = new FormData();
        formData.append('action', 'DeleteUserMessage');
        formData.append('mid', messageID);

        const responseData = await PostData(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, formData, 'text');

        if (responseData) {
            isSuccess = Boolean(responseData);
        }
    }

    return isSuccess;
};

export const updateMessageStatus = async (messageID: string, status: MessageStatus) => {
    let isSuccess: boolean = false;

    if (messageID.toString().trim()?.length > 0 && status) {
        const formData = new FormData();
        formData.append('action', 'UpdateUserMessageStatus');
        formData.append('mid', messageID);
        formData.append('status', status);

        const responseData = await PostData(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, formData, 'text');

        if (responseData) {
            isSuccess = Boolean(responseData);
        }
    }

    return isSuccess;
};

export const sendRecommendationMessage = async (
    friendID: string,
    itemType: MusicItemType,
    itemID: string,
    specified: string = '',
    message: string = ''
) => {
    let isSuccess: boolean = false;

    if (friendID.toString().trim()?.length > 0 && itemType.trim()?.length > 0 && itemID.trim()?.length > 0) {
        const responseData = await PostData(`${BELLINO_BASE_URL}/api/messages`, {
            action: 'RecommendItem',
            friendID,
            itemType,
            itemID,
            specified,
            message
        });

        if (responseData?.success && responseData?.result) {
            isSuccess = Boolean(responseData.result);
        }
    }

    return isSuccess;
};
