import React, { useEffect, useRef, useState } from 'react';

import { FormatDate, GetFixedForamtDate, GetDayName, GetMonthName, GetTime } from '../../Classes/Utilities';

//import classes from './LiveDate.module.css';

export type LiveDateProps = {
    utcDate: string | number | Date;
    showIcon?: boolean;
    className?: string;
};

function LiveDate(props: LiveDateProps) {
    const { utcDate, showIcon, className } = props;

    const [liveDate, setLiveDate] = useState('');
    const [detailedDate, setDetailedDate] = useState('');

    const liveDateTimerInterval = useRef<NodeJS.Timer>();

    /* Get the differnce time between now and the given date. */
    const GetDiffDate = (date: Date) => {
        const inputDate = new Date(date);
        const nowDate = new Date();

        const secondsDiff = Math.floor((nowDate.getTime() - inputDate.getTime()) / 1000);
        const minutesDiff = Math.floor(secondsDiff / 60);
        const hoursDiff = Math.floor(minutesDiff / 60);
        let daysDiff = Math.floor(hoursDiff / 24);
        let weeksDiff = Math.floor(daysDiff / 7);
        let yearsDiff = Math.floor(daysDiff / 365);

        if (nowDate.getHours() * 60 + nowDate.getMinutes() - (inputDate.getHours() * 60 + inputDate.getMinutes()) < 0) {
            daysDiff++;
        }

        if (yearsDiff === 0 && nowDate.getFullYear() - inputDate.getFullYear() > 0) {
            yearsDiff = 1;
        }

        const hourTime = GetTime(inputDate);

        if (yearsDiff > 0 && daysDiff >= 6) {
            return inputDate.getDate() + ' ' + GetMonthName(inputDate) + ', ' + inputDate.getFullYear();
        }

        if (daysDiff > 0 && hoursDiff >= 12) {
            if (weeksDiff === 0 && daysDiff < 6) {
                if (daysDiff === 1) {
                    return `Yesterday, ${hourTime}`;
                } else {
                    return GetDayName(inputDate) + ', ' + hourTime;
                }
            } else {
                return inputDate.getDate() + ' ' + GetMonthName(inputDate) + ', ' + hourTime;
            }
        } else if (hoursDiff > 0) {
            if (hoursDiff === 1) {
                return '1 hour';
            } else if (hoursDiff < 4) {
                return `${hoursDiff}  hours`;
            } else {
                return `Today,  ${hourTime}`;
            }
        } else if (minutesDiff > 0) {
            return minutesDiff > 1 ? `${minutesDiff} minutes` : '1 minute';
        }

        return 'now';
        //return secondsDiff <= 1 ? '1 second' : `${secondsDiff} seconds`;
    };

    useEffect(() => {
        const fixedForamtDate = GetFixedForamtDate(utcDate);

        setDetailedDate(FormatDate(fixedForamtDate, 'Detailed'));
        setLiveDate(GetDiffDate(fixedForamtDate));

        if (liveDateTimerInterval.current) {
            clearInterval(liveDateTimerInterval.current);
        }

        liveDateTimerInterval.current = setInterval(() => {
            //var date = new Date((timeUTC * 1000 - (new Date().getTimezoneOffset())));
            setLiveDate(GetDiffDate(fixedForamtDate));
        }, 30000);

        return () => {
            clearInterval(liveDateTimerInterval.current);
        };
    }, [utcDate]);

    return (
        <span className={className} title={detailedDate}>
            {showIcon && <i className="fa fa-regular fa-clock fs-96 me-35"></i>}
            {liveDate}
        </span>
    );
}

export default LiveDate;
