import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ReleaseInfo } from '../../Types/ReleaseInfo';
import { Album } from '../../Classes/Album';
import { FormatDate } from '../../Classes/Utilities';

import Card from '../Card/Card';
import Tag from '../Tag/Tag';
import ItemPlaybar from '../ItemPlaybar/ItemPlaybar';
import ArtistLink from '../ArtistLink/ArtistLink';

import classes from './ReleaseCard.module.css';

export type ReleaseCardProps = {
    cardSize?: 'large' | 'medium' | 'small' | number;
    releaseInfo: ReleaseInfo;
    showArtists?: boolean;
};

function ReleaseCard(props: ReleaseCardProps) {
    const { cardSize, releaseInfo, showArtists } = props;

    const release = useMemo(() => new Album(releaseInfo), [releaseInfo]);

    return (
        <Card
            cardSize={cardSize}
            imgPath={release.coverImgPath}
            imgOverlays={[
                {
                    showMode: 'onHover',
                    overlayChildren: <ItemPlaybar viewMode="overlay" itemId={release.id} itemType="Album"></ItemPlaybar>
                }
            ]}
            details={
                <div className={classes.releaseDetails}>
                    <div className="fw-500">
                        <Link to={`/album?aid=${release.id}`} title={release.name}>
                            {release.name}
                        </Link>
                    </div>
                    <div className="text-gray">{showArtists && <ArtistLink artists={release.artists}></ArtistLink>}</div>
                    <div className={classes.releaseTags}>
                        {release.genres.map(genreName => (
                            <Tag
                                key={genreName}
                                href={`/genre?name=${encodeURIComponent(genreName)}`}
                                text={`[${genreName}]`}
                                viewMode="link"
                            ></Tag>
                        ))}
                    </div>
                    <div className="fs-105 text-gray">{FormatDate(release.date, 'Short')}</div>
                </div>
            }
        ></Card>
    );
}

export default ReleaseCard;
