import { BELLINO_BASE_URL, FetchData, PostData } from '../Classes/Utilities';
import { MusicItemType } from '../Types/MusicItemType';
import { TrackInfo } from '../Types/Track';
import { BasicVideoInfo } from '../Types/VideoInfo';

export const buildPlaylistByTag = async (tagName: string) => {
    let playlist: TrackInfo[] = [];

    if (tagName.trim().length > 0) {
        const responseData = await FetchData(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&tag=${tagName}`);

        if (responseData) {
            playlist = responseData.map((trackData: any) => {
                return {
                    id: trackData.trackID,
                    name: trackData.trackName,
                    artists: trackData.artists ?? [],
                    videos: [
                        {
                            duration: trackData.duration,
                            externalId: trackData.videoID
                        } as BasicVideoInfo
                    ]
                } as TrackInfo;
            });
        }
    }

    return playlist;
};

export const buildPlaylistByTrackIds = async (trackIds: string[]) => {
    let playlist: TrackInfo[] = [];

    if (trackIds.length > 0) {
        const responseData = await FetchData(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&data=${trackIds.join(',')}`);

        if (responseData) {
            playlist = responseData.map((trackData: any) => {
                return {
                    id: trackData.trackID,
                    name: trackData.trackName,
                    artists: trackData.artists ?? [],
                    videos: [
                        {
                            duration: trackData.duration,
                            externalId: trackData.videoID
                        } as BasicVideoInfo
                    ]
                } as TrackInfo;
            });
        }
    }

    return playlist;
};

export const buildPlaylistBy = async (genres: string[] = [], minYear: number = -1, maxYear: number = -1, level?: number, country?: string) => {
    let playlist: TrackInfo[] = [];

    const genreStr = genres?.join('|')?.toLowerCase() ?? '';

    if (genreStr.trim().length > 0 || minYear > 0 || maxYear > 0 || level) {
        const responseData = await FetchData(
            `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&genre=${genreStr}&minyear=${minYear}&maxyear=${maxYear}${country ? `&cntry=${country}` : ''}${level ? `&level=${level}` : ''}`
        );

        if (responseData) {
            playlist = responseData.map((trackData: any) => {
                return {
                    id: trackData.trackID,
                    name: trackData.trackName,
                    artists: trackData.artists ?? [],
                    videos: [
                        {
                            duration: trackData.duration,
                            externalId: trackData.videoID
                        } as BasicVideoInfo
                    ]
                } as TrackInfo;
            });
        }
    }

    return playlist;
};

export const buildPlaylistByItem = async (itemType: MusicItemType, itemID: string) => {
    let playlist: TrackInfo[] = [];

    if (itemType.trim().length > 0 && itemID.trim().length > 0) {
        const responseData = await FetchData(
            `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&type=${itemType.toLowerCase()}&itemID=${itemID}`
        );

        if (responseData) {
            playlist = responseData.map((trackData: any) => {
                return {
                    id: trackData.trackID,
                    name: trackData.trackName,
                    artists: trackData.artists ?? [],
                    videos: [
                        {
                            duration: trackData.duration,
                            externalId: trackData.videoID
                        } as BasicVideoInfo
                    ]
                } as TrackInfo;
            });
        }
    }

    return playlist;
};

export const buildPlaylistFromUserLibrary = async (userId: string) => {
    let playlist: TrackInfo[] = [];

    if (userId.trim().length > 0) {
        const formData = new FormData();
        formData.append('action', 'BuildPlaylist');
        formData.append('uid', userId);

        const responseData = await PostData(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, formData);

        if (responseData) {
            playlist = responseData.map((trackData: any) => {
                return {
                    id: trackData.trackID,
                    name: trackData.trackName,
                    artists: trackData.artists ?? [],
                    videos: [
                        {
                            duration: trackData.duration,
                            externalId: trackData.videoID
                        } as BasicVideoInfo
                    ]
                } as TrackInfo;
            });
        }
    }

    return playlist;
};
