import { BELLINO_BASE_URL, FetchData } from '../Classes/Utilities';
import { TrackInfo } from '../Types/Track';
import { BasicVideoInfo } from '../Types/VideoInfo';

export const getAlbumTracksPlaylist = async (albumId: string) => {
    let albumTracksPlaylist: TrackInfo[] = [];

    if (albumId?.toString().trim()?.length > 0) {
        const albumTracksData = await FetchData(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&type=album&itemID=${albumId}`);

        if (albumTracksData?.length) {
            albumTracksPlaylist = albumTracksData.map((trackData: any) => {
                return {
                    albumId,
                    id: trackData.trackID,
                    name: trackData.trackName,
                    artists: trackData.artists ?? [],
                    videos: [
                        {
                            duration: trackData.duration,
                            externalId: trackData.videoID
                        } as BasicVideoInfo
                    ]
                } as TrackInfo;
            });
        }
    }

    return albumTracksPlaylist;
};
