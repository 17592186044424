import React, { useRef, useState } from 'react';
import Button from '../../../Components/Button/Button';

function PasswordTab(props: { className: string; onUpdatePassword: (oldPassword: string, newPassword: string) => Promise<boolean> }) {
    const { className, onUpdatePassword } = props;

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [message, setMessage] = useState('');

    const currentPasswordElementRef = useRef(null);
    const newPasswordElementRef = useRef(null);
    const confirmPasswordElementRef = useRef(null);

    const handleOnInputChange = (event: React.InputHTMLAttributes<HTMLInputElement>) => {
        const currentPasswordElement = currentPasswordElementRef?.current ? (currentPasswordElementRef.current as HTMLInputElement) : null;
        const newPasswordElement = newPasswordElementRef?.current ? (newPasswordElementRef.current as HTMLInputElement) : null;
        const confirmPasswordElement = confirmPasswordElementRef?.current ? (confirmPasswordElementRef.current as HTMLInputElement) : null;

        setMessage('');

        if (currentPasswordElement?.value?.length && newPasswordElement?.value?.length && confirmPasswordElement?.value?.length) {
            if (newPasswordElement?.value !== confirmPasswordElement?.value) {
                setIsSaveDisabled(true);
                setMessage('The new password and confirmation password do not match.');
            } else if (newPasswordElement?.value === currentPasswordElement?.value) {
                setIsSaveDisabled(true);
                setMessage('Please choose a different password from your current one.');
            } else {
                setIsSaveDisabled(false);
            }
        } else {
            setIsSaveDisabled(true);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!isSaveDisabled) {
            const currentPasswordElement = currentPasswordElementRef?.current ? (currentPasswordElementRef.current as HTMLInputElement) : null;
            const newPasswordElement = newPasswordElementRef?.current ? (newPasswordElementRef.current as HTMLInputElement) : null;

            if (currentPasswordElement && newPasswordElement) {
                const isUpdated = await onUpdatePassword(currentPasswordElement.value, newPasswordElement.value);

                if (isUpdated) {
                    setMessage('Password updated!');
                    setIsSaveDisabled(true);
                }
            }
        }
    };

    return (
        <div style={{ padding: '1.25rem 2%' }}>
            <form onSubmit={handleSubmit} autoComplete="off">
                <div className={className}>
                    <div>Current Password</div>
                    <input ref={currentPasswordElementRef} type="password" autoComplete="old-password" onChange={handleOnInputChange}></input>
                    <div>New Password</div>
                    <input ref={newPasswordElementRef} type="password" autoComplete="new-password" onChange={handleOnInputChange}></input>
                    <div>Confirm Password</div>
                    <input ref={confirmPasswordElementRef} type="password" autoComplete="new-password" onChange={handleOnInputChange}></input>
                </div>
                <div className="mt-150">
                    <Button viewMode="fancy" isDisabled={isSaveDisabled} onClick={handleSubmit}>
                        Update Password
                    </Button>
                    {message && (
                        <span className="ml-100 fw-500" style={{ color: message.toLowerCase().includes('updated') ? '#32992f' : '#d14b4b' }}>
                            {message}
                        </span>
                    )}
                </div>
            </form>
        </div>
    );
}

export default PasswordTab;
