interface WikiSearchResult {
    title: string;
}

interface WikiPageData {
    pageURL: string;
    pageSummary: string;
}

export const sanitizeWikiText = (wikiText: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = wikiText;

    // remove any references / style
    tempDiv.querySelectorAll('sup, style').forEach(elem => {
        elem.remove();
    });

    // remove cite error
    /*tempDiv.querySelectorAll('.mw-ext-cite-error').forEach(elem => {
        elem.remove();
    });*/

    let sanitizedWikiText = '';

    // Pure wiki text
    tempDiv.querySelectorAll('p').forEach(elem => {
        sanitizedWikiText += elem.innerText + '\n';
    });

    return sanitizedWikiText;
};

export const getWikiPage = async (searchText: string, extraKeyWords?: string[]): Promise<WikiPageData | undefined> => {
    if (!searchText || !searchText.trim().length) {
        return undefined;
    }

    const fullSearchText = searchText.toLowerCase(); //`${searchText.toLowerCase()} ${extraKeyWords?.length ? extraKeyWords.join(' ').toLowerCase() : ''}`.trim();

    // 1. Search wikipedia page name according to searchText
    // 2. Get summary of wiki value
    const searchResponse = await fetch(
        `https://en.wikipedia.org/w/api.php?action=query&format=json&list=search&srsearch=${encodeURIComponent(fullSearchText)}&format=json&formatversion=2&origin=*`
    );

    if (!searchResponse?.ok) {
        return undefined;
    } else {
        const data = await searchResponse.json();

        if (data?.query?.search) {
            const calculateScore = (title: string, index: number): number => {
                let score = 100 - index * 10; // Lower index = higher score
                const lowerTitle = title.toLowerCase();

                if (fullSearchText.includes(lowerTitle.toLowerCase())) {
                    score += 50; // Strong match boost
                }

                for (const keyword of extraKeyWords || []) {
                    if (lowerTitle.includes(keyword.toLowerCase())) score += 20; // Extra keyword boost
                }

                return score;
            };

            // Find best result based on score
            const bestResult = (data.query.search as WikiSearchResult[]).reduce<{ title: string; score: number }>(
                (best, entry, index) => {
                    const score = calculateScore(entry.title, index);
                    return score > best.score ? { title: entry.title, score } : best;
                },
                { title: '', score: -1 }
            );

            if (!bestResult.title) {
                return undefined;
            }

            // Fetch best page data
            const pageName = encodeURIComponent(bestResult.title);
            const pageURL = `https://en.wikipedia.org/wiki/${pageName}`;

            const pageResponse = await fetch(
                `https://en.wikipedia.org/w/api.php?action=parse&format=json&prop=text&section=0&page=${pageName}&format=json&formatversion=2&origin=*`
            );

            if (!pageResponse?.ok) {
                return undefined;
            } else {
                const pageData = await pageResponse.json();
                const pageSummary = sanitizeWikiText(pageData.parse.text);

                return {
                    pageSummary,
                    pageURL
                };
            }
        }
    }
};
