import React, { useEffect, useRef, useState } from 'react';

import useUserSessionData from '../../hooks/useUserSessionData';
import { KeyValue } from '../../Types/KeyValue';
import { MetadataType } from '../../Types/MetadataType';
import { MusicItemType } from '../../Types/MusicItemType';
import { improveItemMetadata } from '../../Services/Common';
import { Track } from '../../Classes/Track';

import Dialog from '../../Containers/Dialog/Dialog';
import Button from '../../Components/Button/Button';
import GenresSelection from '../../Components/GenresSelection/GenresSelection';
import RichSelectionList from '../../Components/SelectionList/RichSelectionList';
import CountriesSelection from '../../Components/CountriesSelection/CountriesSelection';
import GuestDialog from './GuestDialog';

import classes from './ImproveMetadataDialog.module.css';

export type ImproveMetadataDialogProps = {
    itemType: MusicItemType;
    itemId: string;
    isVisible: boolean;
    artistOrigin?: string;
    track?: Track;
    onSave: (type: string, data?: string | string[] | KeyValue) => void;
    onCloseDialog: () => void;
};

function ImproveMetadataDialog(props: ImproveMetadataDialogProps) {
    const { itemType, itemId, isVisible, artistOrigin, track, onSave, onCloseDialog } = props;

    const { userSessionData } = useUserSessionData();

    const selectTypeElementRef = useRef(null);
    const inputElementRef = useRef(null);

    const keyValGenres = useRef<KeyValue>({});
    const keyValTags = useRef<KeyValue>({});

    const [metadataType, setMetadataType] = useState<MetadataType>('name');
    const [newArtistOrigin, setNewArtistOrigin] = useState(artistOrigin);

    const [trackGenres, setTrackGenres] = useState<string[]>();
    const [trackTags, setTrackTags] = useState<string[]>();

    const isLoadedTrackGenres = useRef<boolean>(false);
    const isLoadedTrackTags = useRef<boolean>(false);

    const tags = [
        'Christmas',
        'Duet',
        'Emotional',
        'Eurovision',
        'Forgiveness',
        'Freedom',
        'Goodbye',
        'Guitar',
        'Happiness',
        'Hope',
        'Loneliness',
        'Love',
        'Melancholy',
        'Party',
        'Piano',
        'Relaxing',
        'Sadness',
        'Sensual',
        'Sleeping',
        'Violin',
        'Workout',
        'Winter',
        'Saxophone',
        'Summer'
    ];

    const onSend = () => {
        const inputElement = inputElementRef?.current ? (inputElementRef.current as HTMLInputElement) : null;

        if (metadataType === 'name' || metadataType === 'date') {
            if (inputElement?.value && inputElement.value.trim().length > 0) {
                let input_data: string | object = inputElement.value;

                if (metadataType === 'date') {
                    const valid = !isNaN(Date.parse(inputElement.value));
                    const date = new Date(Date.parse(inputElement.value));

                    input_data = {
                        year: valid ? date.getFullYear() : -1,
                        month: valid ? date.getMonth() + 1 : -1,
                        day: valid ? date.getDate() : -1
                    };
                }

                improveItemMetadata(itemType, itemId, {
                    input_type: metadataType,
                    input_data
                });
                onSave(metadataType, inputElement.value);
                onCloseDialog();
            } else {
                inputElement?.focus();
            }
        } else if (metadataType === 'origin') {
            improveItemMetadata(itemType, itemId, {
                input_type: metadataType,
                input_data: newArtistOrigin ?? ''
            });
            onSave(metadataType, newArtistOrigin);
            onCloseDialog();
        } else if (metadataType === 'tags') {
            improveItemMetadata(itemType, itemId, {
                input_type: metadataType,
                input_data: keyValTags.current
            });
            onSave(metadataType, keyValTags.current);
            onCloseDialog();
        } else if (metadataType === 'genres') {
            improveItemMetadata(itemType, itemId, {
                input_type: metadataType,
                input_data: keyValGenres.current
            });
            onSave(metadataType, keyValGenres.current);
            onCloseDialog();
        }
    };

    const onMetadataTypeChange = (event: any) => {
        const selectTypeElement = selectTypeElementRef?.current ? (selectTypeElementRef.current as HTMLSelectElement) : null;
        const inputElement = inputElementRef?.current ? (inputElementRef.current as HTMLInputElement) : null;

        if (selectTypeElement) {
            if (inputElement) {
                inputElement.value = '';
            }

            setMetadataType(selectTypeElement.value.toLowerCase() as MetadataType);
        }
    };

    useEffect(() => {
        const updateTrackGenres = async () => {
            if (track) {
                setTrackGenres(await track.LoadGenres(true));
                isLoadedTrackGenres.current = true;
            }
        };

        const updateTrackTags = async () => {
            if (track) {
                setTrackTags(await track.LoadTags(true));
                isLoadedTrackTags.current = true;
            }
        };

        if (isVisible && userSessionData.userInfo?.id) {
            if (metadataType === 'genres' && isLoadedTrackGenres.current !== true) {
                updateTrackGenres();
            } else if (metadataType === 'tags' && isLoadedTrackTags.current !== true) {
                updateTrackTags();
            }
        } else {
            isLoadedTrackGenres.current = false;
            isLoadedTrackTags.current = false;

            setTrackGenres(undefined);
            setTrackTags(undefined);
        }
    }, [isVisible, metadataType, track, userSessionData.userInfo?.id]);

    //TODO: add track option
    //<option value="Language">Language correction</option>

    if (!userSessionData.userInfo?.id) {
        return (
            <GuestDialog isVisible={isVisible} userActionDescription={`submit metadata corrections`} onCloseDialog={onCloseDialog}></GuestDialog>
        );
    }

    return (
        <Dialog isVisible={isVisible} title={`Improve ${itemType} Metadata`} onCloseDialog={onCloseDialog}>
            <p className="mb-100">
                Find something wrong? Care to help?
                <br />
                Simply, just suggest your correction:
            </p>
            <select ref={selectTypeElementRef} className={`mb-100 ${classes.metadataInput}`} onChange={onMetadataTypeChange}>
                <option value="Name">Name correction</option>
                {itemType !== 'Artist' && <option value="Date">Date correction</option>}
                {itemType === 'Artist' && <option value="Origin">Origin correction</option>}
                {itemType === 'Track' && (
                    <>
                        <option value="Genres">Genres correction</option>
                        <option value="Tags">Tags correction</option>
                    </>
                )}
            </select>
            {(metadataType === 'name' || metadataType === 'date') && (
                <input ref={inputElementRef} className={classes.metadataInput} placeholder={`${itemType} ${metadataType}`}></input>
            )}
            {metadataType === 'origin' && (
                <CountriesSelection
                    selectedCountries={newArtistOrigin ? [newArtistOrigin] : []}
                    onSelectionChange={newSelectedOptions => {
                        setNewArtistOrigin(newSelectedOptions?.[0]);
                    }}
                ></CountriesSelection>
            )}
            {metadataType === 'genres' && (
                <GenresSelection
                    selectionMode="Rich-Selection"
                    showPreloader={trackGenres === undefined}
                    highlightGenres={trackGenres}
                    onSelectionChange={selectedOptions => {
                        //console.log(selectedOptions);
                        keyValGenres.current = selectedOptions;
                    }}
                ></GenresSelection>
            )}
            {metadataType === 'tags' && (
                <RichSelectionList
                    showPreloader={trackTags === undefined || tags.length === 0}
                    onSelectionChange={selectedOptions => {
                        //console.log(selectedOptions);
                        keyValTags.current = selectedOptions;
                    }}
                    selectionOptions={tags}
                    highlightOptions={trackTags}
                ></RichSelectionList>
            )}
            <div className={classes.footer}>
                <Button viewMode="fancy" onClick={onSend}>
                    Send
                </Button>
            </div>
        </Dialog>
    );
}

export default ImproveMetadataDialog;
