import { BELLINO_BASE_URL, FetchData, PostData } from '../Classes/Utilities';
import { MetadataType } from '../Types/MetadataType';

export const sendFeedback = async (feedbackType: string, feedbackMesage: string) => {
    let isSuccess: boolean = false;

    if (feedbackType.toString().trim().length > 0 && feedbackMesage.toString().trim().length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SendFeedback&type=${feedbackType}&msg=${feedbackMesage}&data=${window.location.href}`,
                {
                    method: 'POST'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const addSimilarItem = async (itemType: string, itemID: string, similarItemID: string) => {
    let isSuccess: boolean = false;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0 && similarItemID.toString().trim().length > 0) {
        const responseData = await FetchData(
            `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=AddItemSimilarity&type=${itemType}&itemID=${itemID}&sid=${similarItemID}`
        );

        if (responseData) {
            isSuccess = true;
        }
    }

    return isSuccess;
};

export const addComment = async (itemType: string, itemID: string, comment: string) => {
    let isSuccess: boolean = false;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0 && comment.toString().trim().length > 0) {
        const formData = new FormData();
        formData.append('action', 'AddComment');
        formData.append('type', itemType);
        formData.append('itemID', itemID);
        formData.append('cmt', comment.replace(/'/g, "''"));

        const responseData = await PostData(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, formData);

        if (responseData) {
            isSuccess = Boolean(responseData);
        }
    }

    return isSuccess;
};

export const deleteComment = async (commentID: string) => {
    let isSuccess: boolean = false;

    if (commentID.toString().trim().length > 0) {
        const responseData = await FetchData(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=DeleteComment&cid=${commentID}`, 'text');

        if (responseData) {
            isSuccess = Boolean(responseData === '1');
        }
    }

    return isSuccess;
};

export const updateLikeItem = async (itemType: string, itemID: string, action: 'Like' | 'Unlike' | 'DeleteLike') => {
    let isSuccess: boolean = false;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=${action}&type=${itemType}&itemID=${itemID}`, {
                method: 'GET'
            });

            if (response.ok) {
                isSuccess = true;
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const improveItemMetadata = async (
    itemType: string,
    itemID: string,
    data: {
        input_type: MetadataType;
        input_data: string | object;
    }
) => {
    let isSuccess: boolean = false;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0 && data) {
        const formData = new FormData();
        formData.append('action', 'ImproveMetadata');
        formData.append('type', itemType);
        formData.append('itemID', itemID);
        formData.append('data', typeof data === 'string' ? data : JSON.stringify(data));

        const responseData = await PostData(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, formData, 'text');

        if (responseData) {
            isSuccess = Boolean(responseData);
        }
    }

    return isSuccess;
};

export const addItemPhoto = async (itemType: string, itemID: string, path: string) => {
    let photoData: any = false;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0 && path.toString().trim().length > 0) {
        const imageData = await FetchData(
            `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=AddItemImage&type=${itemType}&itemID=${itemID}&path=${path}`
        );

        if (imageData?.['ID']) {
            photoData = imageData;
            photoData.Path = `${BELLINO_BASE_URL}/${photoData.Path}`;
        }
    }

    return photoData;
};
