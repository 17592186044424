import React, { useEffect, useState } from 'react';

import { BELLINO_BASE_URL, DurationStrToSeconds, PostData, normalizeText } from '../../../Classes/Utilities';

import Title from '../../../Components/Title/Title';
import Button from '../../../Components/Button/Button';

type NewAlbumInfo = {
    name: string;
    date?: Date;
    artists: string[];
    genres?: string[];
    tracks: AlbumTrack[];
};

type AlbumTrack = {
    name: string;
    duration: number;
    durationStr: string;
    position: number;
    cdNumber?: number;
    artists: string[];
};

function AddAlbum() {
    const [albumTracks, setAlbumTracks] = useState<AlbumTrack[]>([]);
    const [albumDataText, setAlbumDataText] = useState<string>('');

    useEffect(() => {
        const parsedTracks = parseAlbumTracks(albumDataText);
        setAlbumTracks(parsedTracks);
    }, [albumDataText]);

    const parseAlbumTracks = (albumDataText: string): AlbumTrack[] => {
        const lines = albumDataText.split(/\n/); // Split input into lines
        const durationRegex = /\d{1,2}:\d{2}/; // Match mm:ss
        const cdNumberRegex = /\b(?:cd ?\d+|side ?[a-z])\b/i;
        const tracks: AlbumTrack[] = [];
        let cdNumber = 0;

        let isPatternDetermined = false;
        let pattern: 'name-duration' | 'duration-name' | 'name-newline-duration' | null = null;

        for (let i = 0; i < lines.length; i++) {
            const trimmedLine = lines[i].trim();

            // Try to extract duration and name based on current pattern
            const durationMatch = durationRegex.exec(trimmedLine);

            if (!durationMatch) {
                if ((tracks.length > 0 && trimmedLine.includes('feat. ')) || trimmedLine.includes('with ')) {
                    const featArtists = trimmedLine.replace('feat.', '').replace('with ', '').trim().split(', ');
                    tracks[tracks.length - 1].artists.push(...featArtists);
                } else if (cdNumberRegex.exec(trimmedLine)) {
                    cdNumber++;
                }

                continue; // Skip lines without duration
            }

            const durationStr = durationMatch[0];
            let name = '';

            if (!isPatternDetermined) {
                // Check where the duration appears relative to the name
                const beforeDuration = trimmedLine.slice(0, durationMatch.index).trim();
                const afterDuration = trimmedLine.slice(durationMatch.index + durationStr.length).trim();

                if (beforeDuration) {
                    pattern = 'name-duration';
                    name = beforeDuration;
                } else if (afterDuration) {
                    pattern = 'duration-name';
                    name = afterDuration;
                } else if (i > 0 && lines[i - 1].trim()) {
                    pattern = 'name-newline-duration';
                    name = lines[i - 1].trim();
                }

                isPatternDetermined = true;
            } else {
                // Apply the determined pattern
                if (pattern === 'name-duration') {
                    name = trimmedLine.slice(0, durationMatch.index).trim();
                } else if (pattern === 'duration-name') {
                    name = trimmedLine.slice(durationMatch.index + durationStr.length).trim();
                } else if (pattern === 'name-newline-duration') {
                    name = lines[i - 1].trim();
                }
            }

            if (name) {
                const totalTrackIndex = tracks.length + 1;
                const currentCdNumber = cdNumber || 1;
                const cdTrackIndex = tracks.filter(t => t.cdNumber === currentCdNumber).length + 1;

                // Check if the name starts with the current index and clean it up if it does
                if (name.trim().startsWith(`${totalTrackIndex}`) || name.trim().startsWith(`${cdTrackIndex}`)) {
                    name = name.replace(/^\d+[.\s\t]+/, '').trim();
                }

                tracks.push({
                    position: cdTrackIndex,
                    name: normalizeText(name),
                    durationStr,
                    duration: DurationStrToSeconds(durationStr),
                    artists: [],
                    cdNumber: currentCdNumber
                });
            }
        }

        return tracks;
    };

    const onAddAlbum = async () => {
        const albumToAdd: NewAlbumInfo = {
            name: (document.getElementById('album_name') as HTMLInputElement).value,
            date: new Date((document.getElementById('album_date') as HTMLInputElement).value),
            genres: (document.getElementById('album_genres') as HTMLInputElement).value.split(';'),
            artists: (document.getElementById('album_artists') as HTMLInputElement).value.split(';'),
            tracks: albumTracks
        };

        console.log(albumToAdd);

        if (albumToAdd.name.trim().length > 0 && albumToAdd.artists.length > 0 && albumToAdd.tracks.length > 0) {
            const fetchPromises = albumToAdd.tracks.map(albumTrack =>
                PostData(`${BELLINO_BASE_URL}/api/tracks`, {
                    action: 'AddTrackData',
                    Artists: (albumTrack.artists || []).concat(albumToAdd.artists),
                    AlbumArtists: albumToAdd.artists,
                    aname: albumToAdd.name,
                    tname: albumTrack.name,
                    tnum: albumTrack.position,
                    acd: albumTrack.cdNumber || 1,
                    length: albumTrack.duration,
                    genre: albumToAdd.genres?.length ? albumToAdd.genres[0] : '',
                    year: albumToAdd.date ? albumToAdd.date.getFullYear() : undefined,
                    month: albumToAdd.date ? albumToAdd.date.getMonth() + 1 : undefined,
                    day: albumToAdd.date ? albumToAdd.date.getDate() : undefined
                })
            );

            try {
                // Wait for the first promise to resolve to validate the album added only once
                let res: any = await fetchPromises[0];

                // In case of the album created - contine with the rest of the album tracks
                if (res?.success && res.result?.albumID && res.result?.trackID) {
                    const remainingPromises = fetchPromises.slice(1);
                    const remainingResponses = await Promise.all(remainingPromises);

                    console.log('albumID = ', res.albumID, [res].concat(remainingResponses));
                } else {
                    console.error('First promise failed or did not return expected data.');
                }
            } catch (error) {
                console.error('Error occurred during "onAddAlbum":', error);
            }
        }
    };

    return (
        <>
            <Title>Add Album</Title>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <div className="mb-100" style={{ display: 'flex', gap: '.5rem' }}>
                        <input type="text" id="album_name" placeholder="Album Name"></input>
                        <input type="text" id="album_genres" placeholder="Album Genres"></input>
                        <input type="text" id="album_date" placeholder="Album Date"></input>
                        <input type="text" id="album_artists" placeholder="Album Artists"></input>
                    </div>
                    <textarea
                        style={{ width: '100%', height: '300px' }}
                        placeholder="Paste album tracks data here..."
                        value={albumDataText}
                        onChange={e => setAlbumDataText(e.target.value)}
                    />
                    <Button className="mt-100" viewMode="fancy" btnColor="blue" onClick={onAddAlbum}>
                        Add Album
                    </Button>
                </div>
                <div style={{ flex: 1 }}>
                    <ul>
                        {albumTracks.map((track, index) => (
                            <li key={index}>
                                <div>
                                    <strong>{track.name}</strong>
                                </div>
                                <div>{track.durationStr}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default AddAlbum;
