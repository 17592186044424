import React, { useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { Validate } from '../../Classes/Utilities';
import { resetUserPassword } from '../../Services/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Button from '../../Components/Button/Button';
import Link from '../../Components/Link/Link';
import Logo from '../../Components/Logo/Logo';

import classes from './ForgotPassword.module.css';

function ForgotPasswordPage(props: any) {
    usePageMetdata({ title: 'Forgot Password' });
    const { userSessionData } = useUserSessionData();

    const emailInputElementRef = useRef(null);
    const [status, setStatus] = useState<'success' | 'invalid-email' | 'unknown-email' | 'please-wait'>();

    const onResetPasswordClick = async () => {
        const emailInputElement = emailInputElementRef?.current ? (emailInputElementRef.current as HTMLInputElement) : null;

        setStatus(undefined);

        if (emailInputElement?.value?.trim()?.length) {
            if (!Validate.Email(emailInputElement.value)) {
                setStatus('invalid-email');
            } else {
                setStatus('please-wait');

                const response = await resetUserPassword(emailInputElement.value);

                if (response?.success && Boolean(response?.result)) {
                    setStatus('success');
                } else if (response?.message?.toLowerCase()?.includes('unknown email')) {
                    setStatus('unknown-email');
                }
            }
        } else if (!emailInputElement?.value?.trim()?.length) {
            emailInputElement?.focus();
        }
    };

    if (userSessionData.isReady && userSessionData.userInfo?.id) {
        return <Navigate to="/home" />;
    }

    return (
        <AppMainContainer>
            <div className={classes.pageBackground} style={{ backgroundImage: `url('Images/people.jpg')` }}>
                <div className={classes.signupArea}>
                    <Logo href="/home" text="Bellino" viewSize="large"></Logo>
                    <input ref={emailInputElementRef} type="email" placeholder="Email" />
                    {Boolean(status) && (
                        <div className={`${classes.statusMessage} ${status?.includes('invalid') ? classes.errorStatus : classes.successStatus}`}>
                            {status === 'invalid-email' && <span>Invalid email!</span>}
                            {status === 'unknown-email' && (
                                <>
                                    <div>Sorry, that email address is not registered with us.</div>
                                    <div>
                                        Please try again or{' '}
                                        <Link href={'/signup'} textColor="white">
                                            <b>signup</b>
                                        </Link>
                                        .
                                    </div>
                                </>
                            )}
                            {status === 'success' && (
                                <>
                                    <div>Reset Password completed!</div>
                                    <div>
                                        A temporary password was sent to your email account. You may try to{' '}
                                        <Link href={'/'} textColor="white">
                                            <b>signin</b>
                                        </Link>
                                        .
                                    </div>
                                </>
                            )}
                            {status === 'please-wait' && <div>Please wait...</div>}
                        </div>
                    )}
                    {status !== 'success' && (
                        <div className={classes.buttons}>
                            <Button viewMode="fancy" isDisabled={status === 'please-wait'} onClick={onResetPasswordClick}>
                                Reset Password
                            </Button>
                            <Link href="/" viewMode={'Button'} bgColor="green">
                                Cancel
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </AppMainContainer>
    );
}

export default ForgotPasswordPage;
